<template>
	<div id="Services" class="bg-white border border-searchBorder border-t-0 relative text-center mt-5 md:mt-0">
		<span class="h-1 bg-info block w-full absolute top-0"></span>
		<div class="p-8">
			<img loading="lazy" :src="item.img" class="mx-auto object-cover" alt="" />
			<h3 class="text-2xl mt-5 text-Text leading-relaxed">{{ item.title }}</h3>
			<p class="text-dateText mt-4 px-3">
				{{ item.content }}
			</p>
				<router-link
					:to="{ name: item.link }"
					class="bg-btn px-5 py-2 flex items-center justify-center mb-3 mt-5 mx-auto focus:outline-none text-white w-full"
				>
					<span class="mx-2">{{ $t('Home.services.registration') }}</span>
					<img
						:class="$i18n.locale == 'EN' ? 'transform rotate-180' : ''"
						src="../../assets/Vector.svg"
						alt=""
						class="object-cover"
						loading="lazy"
					/>
				</router-link>
				<button
					@click="$emit('fillDialog', { label: item.title, id: item.id })"
					class="text-btn px-5 py-2 flex items-center mx-auto mt-3 focus:outline-none"
				>
					<span class="mx-2 hover:font-bold">{{ $t('Home.services.renew_registration') }}</span>
					<img
						:class="$i18n.locale == 'EN' ? 'transform rotate-180' : ''"
						src="../../assets/VectorBlue.svg"
						alt=""
						class="object-cover"
						loading="lazy"
					/>
				</button>
		</div>
	</div>
</template>
<script>
export default {
	name: 'Services',
	props: ['item'],
	data() {
		return {};
	},
};
</script>
