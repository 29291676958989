<template>
	<transition name="slideTop">
		<div v-if="animate" class="bg-white shadow-lg rounded-lg z-30 p-8 w-3/4 md:w-1/2 lg:w-1/3">
			<div class="flex items-center justify-between mb-5">
				<h3 class="text-gray-700 text-lg font-medium">
					{{
						$t('renewReqForm.enterYourId') +
							' ' +
							(dialog.id == 2 ? $t('renewReqForm.id') : $t('renewReqForm.membership'))
					}}
				</h3>
				<i @click="togglePopup" class="bx bx-x text-gray-500 cursor-pointer text-2xl"></i>
			</div>

			<input
				id="id"
				name="id"
				v-model="id"
				type="number"
				required
				@blur="$v.id.$touch()"
				@keyup.enter="submit"
				:class="defualtInput + ($v.id.$error ? ' border-red-700' : 'border-inputBorder')"
			/>
			<p v-if="$v.id.$error" class="text-red-600 text-sm my-1 w-full">
				{{ $t('renewReqForm.errMsg') }}
			</p>

			<button
				@click="submit"
				class="bg-btn mt-3 flex items-center my-2 px-8 rounded-sm py-2 focus:outline-none text-white"
			>
				<span>{{ $t('renewReqForm.submit') }}</span
				><i v-if="loading" class="text-2xl mx-2 bx bx-loader-circle bx-spin"></i>
			</button>
			<div
				v-if="success"
				class="flex justify-center items-center m-1 font-medium py-1 px-2 rounded-md text-green-100 bg-green-700 border border-green-700"
			>
				<div>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="100%"
						height="100%"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
						class="feather feather-check-circle w-5 h-5 mx-2"
					>
						<path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
						<polyline points="22 4 12 14.01 9 11.01"></polyline>
					</svg>
				</div>
				<div class="text-xl font-normal text-right">
					<h1 class="py-2 font-medium">{{ success }}</h1>
				</div>
				<button
					@click="toggleSuccessMsg"
					class="bg-white flex items-center text-green-700 focus:outline-none font-medium mx-3 text-lg rounded-md px-3 py-1"
				>
					<span>حسناً</span>
				</button>
			</div>
			<p
				v-if="errorMsg"
				@click="errorMsg = null"
				class="px-4 py-2 my-2 border flex items-center rounded-md border-red-600 bg-red-100 text-red-600 font-medium cursor-pointer"
			>
				<i class="bx bxs-error-circle mx-2 text-xl"></i>
				<span class="">{{ errorMsg }}</span>
			</p>
		</div>
	</transition>
</template>
<script>
import { required, numeric } from 'vuelidate/lib/validators';

export default {
	name: 'renew_req_form',
	props: ['dialog'],
	data() {
		return {
			animate: false,
			defualtInput:
				'p-2  bg-white rounded-md border w-full text-right focus-within:border-primary hover:border-primary focus:border-primary focus:outline-none focus:shadow-md my-1',
			id: null,
			errorMsg: null,
			loading: false,
			success: null,
		};
	},
	created() {
		setTimeout(() => {
			this.animate = true;
		}, 10);
		document.addEventListener('keyup', (evt) => {
			if (evt.keyCode === 27) {
				this.togglePopup();
			}
		});
	},
	validations: {
		id: { required, numeric },
	},
	methods: {
		togglePopup() {
			this.$emit('toggle', null);
		},
		submit() {
			this.$v.id.$touch();
			if (this.$v.id.$pending || this.$v.id.$error) return;
			this.loading = true;
			if (this.dialog.id == 1) {
				this.$store
					.dispatch('renew_req_doctor', this.id)
					.then((res) => {
						this.success = res.data.message;
						this.loading = false;
					})
					.catch((err) => {
						this.errorMsg = err.data.message;
						this.loading = false;
						setTimeout(() => {
							this.errorMsg = null;
						}, 15000);
					});
			} else if (this.dialog.id == 2) {
				this.$store
					.dispatch('renew_req_company', this.id)
					.then((res) => {
						this.success = res.data.message;
						this.loading = false;
					})
					.catch((err) => {
						this.errorMsg = err.data.message;
						this.loading = false;
						setTimeout(() => {
							this.errorMsg = null;
						}, 15000);
					});
			} else if (this.dialog.id == 3) {
				this.$store
					.dispatch('renew_req_lab', this.id)
					.then((res) => {
						this.success = res.data.message;
						this.loading = false;
					})
					.catch((err) => {
						this.errorMsg = err.data.message;
						this.loading = false;
						setTimeout(() => {
							this.errorMsg = null;
						}, 15000);
					});
			}
		},
		toggleSuccessMsg() {
			this.togglePopup();
			this.$router.push({ name: 'Home' });
		},
	},
};
</script>
