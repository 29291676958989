<template>
	<div id="services">
		<Header :title="$t('services.header.title')" :description="$t('services.header.description')" />
		<div class="bg-white pt-12">
			<div class="container mx-auto px-5 lg:px-8 ">
				<h2 class="text-footerBg text-3xl mb-5 mt-20">
					<span> {{ $t('services.servives') }}</span>
					<span class="bg-info w-16 my-2 h-1 rounded-lg block "></span>
				</h2>
				<div class="mt-16 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12 lg:gap-16 mx-auto">
					<Service v-for="(item, index) in data" :key="index" :item="item" @fillDialog="fillDialog" />
				</div>

				<h2 class="text-footerBg block text-3xl mb-5 mt-24 ">
					<span>{{ $t('services.term') }}</span>
					<span class="bg-info w-16 my-2 h-1 rounded-lg block "></span>
				</h2>
				<div class="mt-16 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12 lg:gap-16 mx-auto">
					<div v-for="(item, index) in joinTerms" :key="index" class="border border-searchBorder p-5">
						<h5 class="text-btn text-3xl font-bold tracking-wide leading-10 mb-4">{{ item.id }}</h5>
						<p class="text-newsDescription text-xl font-medium ">{{ item.content }}</p>
					</div>
				</div>
			</div>
		</div>
		<div
			v-if="toggle"
			class="fixed inset-0 flex items-center justify-center z-40"
			style="background: rgba(0, 0, 0, 0.3)"
		>
			<renewReqForm :dialog="dialog" @toggle="fillDialog" />
		</div>
	</div>
</template>
<script>
import Header from '@/components/layout/Header';
import doctor from '../assets/Group.svg';
import companyImg from '../assets/company.svg';
import Frame from '../assets/Frame.svg';
import Service from '../components/utils/Service';
import renewReqForm from '../components/layout/renewReqForm';
export default {
	name: 'services',
	components: {
		Header,
		Service,
		renewReqForm,
	},
	data() {
		return {
			dialog: null,
			toggle: false,
			data: [
				{
					img: doctor,
					id: 1,
					title: this.$t('services.serviesCards.doctor.title'),
					content: this.$t('services.serviesCards.doctor.content'),
					link: 'indivualGeneral',
				},
				{
					img: companyImg,
					id: 2,
					title: this.$t('services.serviesCards.company.title'),
					content: this.$t('services.serviesCards.company.content'),
					link: 'companyGeneral',
				},
				{
					img: Frame,
					id: 3,
					title: this.$t('services.serviesCards.lab.title'),
					content: this.$t('services.serviesCards.lab.content'),
					link: 'Labs',
				},
			],
			joinTerms: [
				{
					id: 1,
					content: this.$t('services.joinTerms.0.content'),
				},
				{
					id: 2,
					content: this.$t('services.joinTerms.1.content'),
				},
				{
					id: 3,
					content: this.$t('services.joinTerms.2.content'),
				},
				{
					id: 4,
					content: this.$t('services.joinTerms.3.content'),
				},
				{
					id: 5,
					content: this.$t('services.joinTerms.4.content'),
				},
				{
					id: 6,
					content: this.$t('services.joinTerms.5.content'),
				},
			],
		};
	},
	methods: {
		fillDialog(data) {
			if (data) {
				this.toggle = true;
				this.dialog = data;
			} else {
				this.toggle = false;
				this.dialog = null;
			}
		},
	},
};
</script>
